import { accountServices } from '@/modules/account/services/accountService';
import { authService } from '@/modules/account/services/authService';
import { validatePassword } from '@/utils/validate';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'ChangePassword'
})
export default class extends Vue {
  loading = false;
  manage = {} as account.changePassword;

  showPassword = {
    old: false,
    new: false,
    confirm: false
  };

  get validationRoles(): any {
    return {
      oldPassword: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (!value) {
              callback(new Error(`${this.$t('changePassword.required.password')}`));
            } else if (!validatePassword(value)) {
              callback(new Error(`${this.$t('changePassword.required.passwordPattern')}`));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }
      ],
      newPassword: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (!value) {
              callback(new Error(`${this.$t('changePassword.required.newPassword')}`));
            } else if (!validatePassword(value)) {
              callback(new Error(`${this.$t('changePassword.required.passwordPattern')}`));
            } else {
              if (this.manage.confirmPassword !== '') {
                (this.$refs.changePassword as any).validateField('confirmPassword');
              }
              callback();
            }
          },
          trigger: 'blur'
        }
      ],
      confirmPassword: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (!value) {
              callback(new Error(`${this.$t('changePassword.required.confirmPassword')}`));
            } else if (value !== this.manage.newPassword) {
              callback(new Error(`${this.$t('changePassword.required.confirmPasswordMatch')}`));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }
      ]
    }
  }

  public async change() {
    await (this.$refs.changePassword as any).validate(async valid => {
      this.loading = true;
      if (valid) {
        accountServices
          .ChangePassword(this.manage)
          .then(() => {
            this.$message({
              message: `<strong>${this.$t('changePassword.success')}</strong><br>${this.$t('changePassword.successRedirect')}`,
              showClose: true,
              dangerouslyUseHTMLString: true,
              type: 'success',
              duration: 5000
            });
            this.loading = false;
            setTimeout(() => {
              authService.Logout().then(() => {
                location.reload();
              });
            }, 1500);
          })
          .catch(err => {
            let errors: string = null;
            if (err && err.exceptionMessage) {
              errors = err.exceptionMessage;
            } else if (err && err.response && err.response.exceptionMessage) {
              errors = err.response.exceptionMessage;
            } else if (err && err.response && err.response.data && err.response.data.length > 0) {
              errors = (err.response.data as string[]).reduce((a, b) => `${a}, ${b}`);
            }

            let _message = `${this.$t('changePassword.error')}`;
            if (errors) {
              _message += `<br><i>${errors}</i>`;
            }

            this.$message({
              message: _message,
              showClose: true,
              dangerouslyUseHTMLString: true,
              type: 'error',
              duration: 5000
            });
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    });
  }
}

import { Component, Prop, Vue } from 'vue-property-decorator';
import { anagraficaService } from '@/modules/anagrafica/services/anagraficaService';
import { enteService } from '@/modules/enti/service/enteService';

@Component({
  name: 'UserCard'
})
export default class extends Vue {
  @Prop({ required: true })
  user: user.summary;

  ente: ente.item = null;
  assenzeIngiustificate: number = 0;
  async created() {
    this.assenzeIngiustificate = 0;
    if (this.user?.anagraficaGiornalistaId) {
      this.assenzeIngiustificate = (await anagraficaService.Detail(this.user.anagraficaGiornalistaId))?.contatoreBlacklist ?? 0;
    }
    if (this.user?.anagraficaEnteId) {
      this.ente = await enteService.Detail(this.user.anagraficaEnteId);
    }
  }
}

import { userService } from '@/modules/users/services/userService';
import { store } from '@/store';
import { ElForm } from 'element-ui/types/form';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Account'
})
export default class extends Vue {
  @Prop({ required: true })
  user: user.item;
  manage: user.item = null;

  get validationRules() {
    return {
      nome: [
        {
          required: true,
          message: `${this.$t('profile.required.firstName')}`,
          trigger: 'blur'
        }
      ],
      cognome: [
        {
          required: true,
          message: `${this.$t('profile.required.lastName')}`,
          trigger: 'blur'
        }
      ]
    };
  }

  created() {
    this.manage = this.user;
  }

  cancel() {
    this.manage = this.user;
  }

  loading = false;
  async submit() {
    (this.$refs.dataForm as ElForm).validate(async valid => {
      if (valid) {
        this.loading = true;
        userService.Update(this.manage)
          .then(async () => {
            this.$message({
              message: `${this.$t('profile.success')}`,
              type: 'success',
              duration: 5 * 1000
            });
            this.loading = false;
          })
          .catch(() => {
            this.$message({
              message: `${this.$t('profile.error')}`,
              showClose: true,
              dangerouslyUseHTMLString: true,
              type: 'error',
              duration: 5000
            });
            this.loading = false;
          });
      }
    });
  }
}

import { Component, Vue, Watch } from 'vue-property-decorator';
import { store } from '@/store';
import { userService } from '../users/services/userService';
import UserCard from './components/user-card.vue';
import Account from './components/account.vue';
import ChangePassword from './components/change-password.vue';
import ViewAbsences from './components/view-absences.vue';
import CreditiGiornalistaOverview from '@/modules/offertaFormativa/components/crediti-giornalista-overview.vue'
import CreditiSpecialiOverview from '../creditiSpeciali/components/creditiSpecialiOverview/creditiSpecialiOverview.vue';
import PartecipanteAiCorsiOverview from '@/modules/giornalisti/components/partecipanteAiCorsi/PartecipanteAiCorsi.vue'

@Component({
  name: 'Profile',
  components: {
    UserCard,
    Account,
    ChangePassword,
    CreditiGiornalistaOverview,
    CreditiSpecialiOverview,
    PartecipanteAiCorsiOverview,
    ViewAbsences
  }
})
export default class extends Vue {
  private activeTab = 'account';
  user: user.item = null;

  async created() {
    if (store.state.login.userInfo) {
      this.user = await userService.Detail(store.state.login.userInfo.id);
    }
  }

  get userInfo() {
    return store.state.login.userInfo;
  }

  @Watch("userInfo")
  async userInfoChange(n, o) {
    if (n && n !== o)
      this.user = await userService.Detail(n.id);
  }
}
